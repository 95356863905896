@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap');

$cc_darkgrey: #262626;
$cc_grey: #363636;
$cc_lightgrey: #C0C0C0;
$cc_white: white;
$cc_lightblue: #8CDEF8;

$phone-width: 550px;
$tablet-width: 900px;

// * {
//   background: #000 !important;
//   color: #0f0 !important;
//   outline: solid #f00 1px !important;
// }

html {
  scroll-behavior: smooth;
  -webkit-scroll-behaviour: smooth;

  overflow-x: hidden;
}

body {
  font-family: Poppins, sans-serif;
  background-color: $cc_darkgrey;
  color: $cc_white;
  line-height: 1.8em;

  overflow-x: hidden;
}

p {
  font-size: 0.95em;
}

a.anchor {
  visibility: hidden;
  position: absolute;
  left: 0;
}

a.link-highlight {
  color: $cc_lightblue;
  display: inline-block;
}

h2 {
  font-size: 1.5em;
  color: $cc_lightblue;
  font-weight: bold;
}

.container {
  position: relative;
}

.content {
  position: 1px solid red;

  position: relative;
  max-width: 800px;
  margin: auto;
  padding: 50px 50px;
  left: 0;
  right: 0;
  top: 0;
}

.section {
  background: $cc_darkgrey;
}

.section-break {
  overflow: hidden;

  display: flex;
  flex-flow: row wrap;

  width: 100vw;
  padding-top: 100px;
  background: $cc_darkgrey;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }

  padding-bottom: 50px;

  @media screen and (max-width: $phone-width) {
    img {
      width: 150%;
    }
    padding-bottom: 0;
  }
}

.white {
  background-color: white !important;
}

.grey {
  background-color: $cc_grey !important;
}

a {
  text-decoration: none;
  color: $cc_white;

  transition: color 0.2s;

  &:after {
    content: '';
    display: block;
    height: 2px;
    width: 0;
    background-color: white;
    position: absolute;

    transition: width 0.2s;
  }

  &:hover, &:active {
    transition: color 0.2s;

    &:after {
      width: 30px;

      transition: width 0.2s;
    }
  }
}

.btn {
  &:after {
    display: none;
  }

  padding: 7px 20px;
  display: inline-block;

  border-radius: 5px;
  border: 3px solid $cc_white;
  color: $cc_white;

  background-color: rgba(255, 255, 255, 0);
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    transition: background-color 0.2s;
  }
}

/**
/* Navigation
/***/

nav {
  box-sizing: border-box;
  background: linear-gradient(rgba(26, 26, 26, 0.75), rgba(26, 26, 26, 0.5), rgba(0, 0, 0, 0));
  z-index: 1;
  position: fixed;

  right: 0;
  padding-right: 100px;
  top: 0;
  padding-top: 20px;
  width: 100vw;
  text-align: right;

  font-size: 0.8em;

  height: 100px;

  text-transform: uppercase;

  #btn-contact {
    font-weight: bold;
    display: inline;
    padding: 10px 30px;
  }

  #logo-nav {
    width: 200px;
    float: left;
    padding: 20px 0 0 50px;
    opacity: 0.8;

    @media screen and (max-width: $phone-width) {
      padding: 25px 0 0 20px;
    }

    cursor: pointer;

    transition-duration: 0.2s;
  }

  #logo-nav:hover {
    opacity: 1;
    transition-duration: 0.2s;
  }
}

nav > ul {
  list-style: none;
  padding-left: none;
}

nav ul > li {
  vertical-align: top;
  display: inline-block;
  padding: 5px 0;

  text-align: left;

  width: 100px;
}

.li-header:hover {
  font-weight: bold;
}

nav ul > li {

  ul {
    margin-top: 10px;
    padding: 0;

    overflow-y: hidden;
    width: 200px;
    height: 0;

    transition: height 0.2s;

    li {
      text-align: left;
      display: block;
      padding: 0 0;
      width: 200px;
    }
  }
}

li:hover {
  ul {
    height: 150px;
    font-weight: 400;
    display: block;
    padding: 0;
    text-shadow: rgba(0, 0, 0, 0.5) 3px 3px 5px;

    transition: height 0.5s;
  }
}

#menu-btn {
  display: none;
}

.hide {
  opacity: 0 !important;
  cursor: default !important;

  transition-duration: 0.5s;
}

#phone-menu {
  display: none;
}

#scroll-up-btn {
  position: fixed;
  color: $cc_lightblue;
  right: 50px;
  bottom: 80px;

  z-index: 2;

  font-size: 3em;

  opacity: 0.5;

  transition-duration: 0.2s;
}

#scroll-up-btn:hover {
  opacity: 1;
  cursor: pointer;

  transition-duration: 0.2s;
}

@media screen and (max-width: $tablet-width) {
  nav {
    padding-right: 50px;

    .btn-contact-container {
      display: none;
    }

    #work-nav {
      display: none;
    }

    #services-nav {
      display: none;
    }

    #menu-btn {
      display: inline-block;
      width: 0;
      padding: 10px;
      margin: 0;
    }
  }

  #phone-menu {
    display: block;
    text-align: right;

    padding: 20px 20px;
    margin: 0;
    position: absolute;
    right: 20px;

    background: rgba(26, 26, 26, 0.8);

    li {
      font-size: 1.2em;
      line-height: 2.5em;
      display: block;
      text-align: right;
      padding: 0;
      margin: 0;
      width: auto;

      a {
        display: block;
        margin: 0;
      }

      a::after {
        position: absolute;
        right: 15px;
      }

      .btn {
        margin-top: 20px;
        padding: 5px 30px;
        width: auto;
      }
    }
  }
}

#splash {
  height: 75vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0);
}

.splash-photo {
  width: 100vw;
  height: 75vh;

  object-fit: cover;
  object-position: center;

  position: fixed;
  z-index: -1;
}

.splash-title {
  position: absolute;
  bottom: 25vh;
  margin-bottom: 25px;
  margin-left: 10%;

  text-transform: uppercase;
  font-size: 1.5em;
  line-height: 2em;
  text-shadow: rgba(0, 0, 0, 0.5) 5px 5px 5px;
}

#terms {
  p {
    padding-top: 50px;
  }
}

#contact {
  box-sizing: border-box;
  height: 100vh;
  padding: 40px 0 60px 0;

  @media screen and (max-width: $tablet-width) {
    height: auto;
  }

  @media screen and (max-width: $phone-width) {
    height: auto;
  }

  #contact-form {
    display: flex;
    flex-flow: column wrap;

    height: 210px;

    input {
      width: 30%;
    }

    input, textarea {
      border: none;
      outline: none;

      background-color: #333333;
      border-radius: 5px;

      color: #F2F2F2;
      padding: 15px;
      margin: 6px 0;
      margin-right: 10px;
      flex-grow: 1;
    }

    #input-proj-desc {
      width: 60%;
      -webkit-height: 160px;
      resize: none;

      line-height: 1.5em;
    }

    @media screen and (max-width: $tablet-width) {
      flex-flow: row wrap;
      height: auto;

      input {
        width: 35%;
        flex-grow: 1;
      }

      input[type='text'] {
        order: 0;
      }

      input[type='email'] {
        order: 2;
      }

      input[type='tel'] {
        order: 1;
      }

      textarea {
        width: 100%;
        order: 3;
      }

      #input-proj-desc {
        width: 100%;
        height: 100px;
      }

      #contact-prompt {
        order: 5;
      }
    }
  }

  #contact-prompt {
    display: block;
    font-size: 0.75em;

    padding: 0;
    margin: 0;
    color: #666;
  }

  #send-form {
    padding: 10px;
    margin-top: 30px;
    text-align: center;
    width: 120px;

    cursor: pointer;
  }
}

.footer {
  min-height: 275px;
  background-color: #262626;

  div {
    padding-top: 40px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    max-width: 1000px;
  }

  img {
    padding: 0 10px;
    margin: 50px 0;
    width: 80%;
    max-width: 300px;
    object-fit: contain;
  }

  @media screen and (max-width: $phone-width) {
    img {
      margin: 25px 0;
    }

    padding-bottom: 40px;
  }

  ul {
    font-size: 0.9em;
    padding: 0 10px;
    list-style: none;

    li:first-child {
      font-weight: 600;
      margin-bottom: 20px;
    }
  }

  #brands-li {
    font-size: 1.4em;
    display: flex;
    justify-content: space-between;
    width: 150px;

    a:after {
      height: 1px;
    }

    a:hover:after {
      width: 10px;
    }
  }
}

#copyright {
  background-color: #1b1b1b;
  display: block;
  height: 60px;

  p {
    padding: 15px 0;
    margin: 0;
    margin-left: 50px;

    font-size: 0.8em;
    color: #BDBDBD;

    float: left;
  }

  #web-author {
    float: right;
    margin: 0;
    margin-right: 100px;

    @media screen and (max-width: $phone-width) {
      display: none;
    }
  }
}
