$phone-width: 550px;
$tablet-width: 900px;

#page-home {
  #splash-video {
    position: relative;
    height: 100vh;
    z-index: 0;

    #video-reel {
      width: 100vw;
      height: 100vh;

      object-fit: cover;
      overflow: hidden;
      object-position: center;
    }

    #splash-pattern-fill {
      position: absolute;

      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      background: url('../resources/vid-pattern.png');
      background-size: 9px;
    }

    #splash-title {
      position: absolute;
      left: 50vw;
      top: 50vh;

      transform: translate(-50%, -50%);

      img {
        width: 400px;
      }

      h3 {
        font-weight: 300;
        color: white;
        text-align: center;
        margin: 0;
      }

      @media screen and (max-width: $phone-width) {
        img {
          max-width: 85vw;
          width: 350px;
        }

        h3 {
          position: relative;

          span {
            position: relative;
            display: inline-block;
          }

          display: block;
          font-size: 1em;
        }
      }
    }

    #btn-enquire {
      position: absolute;
      left: 0;
      right: 0;
      width: 120px;

      text-align: center;
      margin: auto;
      bottom: 10vh;

      padding: 15px 40px;
    }

    @media screen and (max-width: $phone-width) {
      #video-reel {
        height: 100vh;
      }
      #splash-title {
        top: 42vh;
      }

      #btn-enquire {
        bottom: 25vh;
      }
    }
  }

  #mission {
    background: linear-gradient(to bottom right, #1f1f1f, $cc_grey) !important;
    padding: 30px 0 50px 0;

    p {
      font-size: 1em;
      line-height: 1.8em;
    }

    #photo-container {
      float: right;
      padding-left: 50px;

      @media screen and (max-width: $tablet-width) {
        display: none;
      }

      #director-photo {
        filter: grayscale(1);
        width: 130px;
        height: 130px;
        border-radius: 10px;
        text-align: right;
        padding: 0;
        margin: 0;

        transition-duration: 0.2s;

        &:hover {
          filter: none;
          transition-duration: 0.2s;
        }
      }

      h6 {
        span {
          display: block;
          margin-bottom: 10px;
        }

        line-height: 1.5em;
        padding: 20px 0 0 0;
        margin: 0;

        text-align: right;
        font-weight: 400;
        font-size: 0.8em;
      }
    }
  }

  #clients {
    padding: 50px 0;
    background-color: #fff;
    text-align: center;

    .content {
      @media screen and (max-width: $phone-width) {
        padding: 50px 15px;
      }
    }

    h2 {
      text-align: center;
      color: #262626;
    }

    ul {
      padding: 0;
      margin: 0;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;

      li {
        display: inline-block;

        img {
          padding: 15px 30px;
          filter: grayscale(1);
          width: 100px;
          height: 100px;
          object-fit: contain;

          transition: 0.2s filter;

          @media screen and (max-width: $tablet-width) {
            padding: 15px 20px;
          }

          @media screen and (max-width: $phone-width) {
            width: 95px;
            padding: 15px 10px;
          }
        }

        img:hover {
          filter: grayscale(0);
          transition: 0.2s filter;
        }
      }
    }
  }

  #sb-1 {
    min-height: 100vh;

    box-sizing: border-box;

    padding-top: 0;
    padding-bottom: 100px;
    margin: 0;

    h2 {
      margin: 0;
      padding: 50px 0 0 0;
    }

    .image-break {
      position: relative;
      display: flex;
      width: 100vw;
      flex-flow: row wrap;
      justify-content: center;

      padding: 0;

      li {
        position: relative;
        flex-grow: 0;
        list-style: none;
        min-width: 250px;
        width: 25%;
        display: inline-block;
        padding: 0;

        @media screen and (max-width: $phone-width) {
          flex-grow: 1;
          height: 250px;
          overflow-y: hidden;
        }

        &:hover {
          img {
            transition: opacity 0.2s;
            opacity: 0.3;
          }

          cursor: pointer;
        }

        img {
          width: 100%;
          object-fit: cover;

          display: block;
          padding: 0;
          margin: 0;


          transition: opacity 0.2s;
        }

        h4 {
          width: 100px;
          display: inline-block;
          text-transform: uppercase;
          font-size: 2em;
          position: absolute;
          bottom: 0;
          margin: 0 0 20px 20px;
        }
      }
    }
  }

  #sb-2 {
    padding-top: 0;
  }

  #pipeline {
    background: #fff url('../resources/process.png');
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;

    .content {
      padding-bottom: 350px;
    }

    h2, p {
      color: #262626;
    }
  }

  #budget {
    display: flex;
    flex-flow: row wrap;
    background-color: white;
    color: #262626;

    .ind-budget {
      box-sizing: border-box;
      padding: 50px;
      width: 33.3%;
      flex-grow: 1;
      min-width: 350px;

      &:nth-child(even) {
        background-color: #F2F2F2;

      }
    }

    h2 {
      text-align: center;
      margin: 0;
      color: #262626;
    }

    h4 {
      font-weight: 400;
      text-align: center;
      // text-transform: ;
    }
  }

  .service-forms {
    $cc_darkgrey: #262626;
    background-color: #FFFFFF;
    height: 550px;
    padding: 0;

    @media screen and (max-width: $phone-width) {
      height: auto;
    }

    #photo-bg, #live-bg {
      right: 100px;
    }

    #video-bg {
      left: 75px;
    }

    img {
      position: absolute;
      opacity: 0.5;
      height: inherit;

      @media screen and (max-width: $tablet-width) {

        display: none;
        left: 20px;
      }
    }

    h2 {
      margin: 0;
      padding: 0;
      color: $cc_darkgrey;
    }

    p {
      color: $cc_darkgrey;
      max-width: 550px;
    }

    a {
      border: 3px solid $cc_darkgrey;
      color: $cc_darkgrey;
    }

    a:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  #video {
    background-color: #F2F2F2;
    text-align: right;

    @media screen and (max-width: $phone-width) {
      text-align: left;
    }

    p {
      display: inline-block;
    }
  }

  #portfolio-cut, #portfolio-cut-2 {
    background-color: #262626;
    height: 50px;

    p {
      font-size: 0.8em;
      padding-right: 100px;
      float: right;

      @media screen and (max-width: $phone-width) {
        padding-right: 50px;
      }
    }
  }

  #portfolio-cut-2 {
    background-color: #F2F2F2;

    p, a, a:hover {
      color: #262626;
    }

    a:after, a:hover:after {
      background-color: #262626;
    }
  }

  .h2-icon {
    margin-right: 8px;
  }
}
