$phone-width: 550px;
$tablet-width: 900px;

.page-work {

  #crop-prompt {
    color: #262626;
    padding-left: 120px;
    padding-top: 50px;
    margin: 0;
    padding-bottom: 0;

    font-style: italic;

    @media screen and (max-width: $phone-width) {
      display: none;
    }
  }

  .video-section {
    padding: 50px 0 50px 0;

    @media screen and (max-width: $phone-width) {
      padding: 1px 0;
    }
  }

  .video-player-container {
    &:nth-child(even) {
      background-color: white;
      color: $cc_darkgrey;
    }

    &:nth-child(odd) {
      background-color: #f0f0f0;
      color: $cc_darkgrey;
    }

    font-size: 0.9em;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;

    width: 900px;
    margin: 25px auto;

    box-shadow: 0 5px 5px $cc_darkgrey;

    .video-player-text {
      align-self: center;
      position: relative;
      order: 2;
      width: 350px;
      padding: 10px 25px;

      h4 {
        font-size: 1.5em;
        margin: 0;
        padding: 0;
      }

      h5 {
        margin: 0;
        font-size: 1em;
        font-weight: 400;
        text-transform: uppercase;
      }

      .tagline {
        margin-top: 10px;
        font-size: 0.9em;
      }

      .desc {
        font-size: 0.9em;
      }

      .credit {
        margin: 0;
      }
    }

    .video-container-parent {
      align-self: center;
      background-color: $cc_darkgrey;

      display: block;
      flex-grow: 1;
      height: 100%;

      .video-container {
        display: block;
        box-sizing: border-box;
        position: relative;
        padding: 0;

        iframe {
          display: block;
          position: absolute;
          right: 0;
          top: 0;
          left: 0;
          bottom: 0;

          width: 100%;
          height: 100%;
        }
      }

      .a235 {
        padding-top: 42.50%;
      }

      .a200 {
        padding-top: 50.00%;
      }

      .a196 {
        padding-top: 51.03%;
      }

      .a185 {
        padding-top: 54.05%;
      }

      .a178 {
        padding-top: 56.24%;
      }

      .a100 {
        padding-top: 100%;
      }
    }

    &.video-stretch {
      flex-flow: column;
      margin: 50px auto;

      .video-player-text {
        box-sizing: border-box;
        padding: 50px 25px 30px 25px;
        width: 90%;
        margin: 0 auto;
      }

      .video-container-parent {
        order: 2;
        width: 100%;
      }
    }

    @media screen and (max-width: $tablet-width) {
      flex-flow: column;
      width: 90%;
      margin: 50px auto;

      .video-player-text {
        box-sizing: border-box;
        padding: 50px 25px 30px 25px;
        width: 90%;
        margin: 0 auto;
      }

      .video-container-parent {
        order: 2;
        width: 100%;
      }
    }
  }

  .photo-box {
    line-height: 0;
    column-count: 3;
    column-gap: 20px;

    list-style: none;
    max-width: 1100px;
    padding: 50px;
    background-color: white;

    margin: 0 auto;

    li {
      width: 100%;
      height: auto;
      padding-top: 20px;

      img {
        width: 100%;
        object-fit: contain;

        padding: 0;
        margin: 0;
      }
    }

    @media screen and (max-width: $tablet-width) {
      column-count: 2;
    }

    @media screen and (max-width: $phone-width) {
      column-count: 1;
      padding: 50px 25px;

      li {
        padding-top: 25px;
      }
    }

    #photo-box-last {
      flex-grow: 10;
    }
  }
}
